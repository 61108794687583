import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles, TextField, Button, Typography, Link } from "@material-ui/core"

import AppFooter from "../AppFooter"
import AppForm from "../AppForm"
import isEmail from "validator/lib/isEmail"
import isTel from "validator/lib/isMobilePhone"
import { navigate } from "gatsby"
import FormFeedback from "../formFields/FormFeedback"
import { Auth } from "aws-amplify"
import queryString from "query-string"

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
      //fontWeight: "bold"
    },
    reVerifyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[100]
    },
    feedback: {
      marginTop: theme.spacing(2)
    },
    dropDown: {
      marginTop: 18
    },
    pointerClick: {
      cursor: "pointer"
    }
  })

interface SigninProps extends WithStyles<typeof styles> {
  onSignedIn?: () => void
}

const formVal = {
  value: "",
  isDirty: false,
  errorMessage: ""
}

const Signin = (props: SigninProps) => {
  const { classes } = props
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [formFields, setFormFields] = React.useState({
    email: { ...formVal },
    password: { ...formVal }
  })
  const [feedBackError, setFeedBackError] = React.useState("")
  const [feedBackSuccess, setFeedBackSuccess] = React.useState("")
  const [formDisabled, setFormDisabled] = React.useState(false)

  React.useEffect(() => {
    let formIsValid = true
    for (let key in formFields) {
      if (!!formFields[key].errorMessage || !formFields[key].value) {
        formIsValid = false
        break
      }
    }
    setSubmitDisabled(!formIsValid)
  }, [formFields])

  const formHandler = e => {
    const newForState = {
      ...formFields,
      [e.target.name]: {
        value: e.target.value.trim(),
        isDirty: true,
        errorMessage: validate(e.target.name, e.target.value)
      }
    }
    setFormFields(newForState)
  }

  const validate = (name: string, value: string) => {
    if (["email", "password"].indexOf(name) >= 0) {
      if (value.length == 0) return "field required"
      if (name == "email") {
        return !isEmail(value.trim()) ? "Invalid email" : null
      }
    }
    return ""
  }

  const submit = async e => {
    e.preventDefault()
    try {
      await Auth.signIn(formFields.email.value, formFields.password.value)
      setFeedBackSuccess("Signed in")
      setFeedBackError("")
      setTimeout(() => {
        if (!!props.onSignedIn) {
          props.onSignedIn()
        } else {
          const queryStr = queryString.parse(location.search)
          if (queryStr && !!queryStr.source) {
            navigate(queryStr.source as string)
          } else {
            navigate("/course")
          }
        }
      }, 200)
    } catch (err) {
      console.log(err)
      setFeedBackSuccess("")
      setFeedBackError(err.message)
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            <Link onClick={() => navigate("/sign-up")} underline="always" className={classes.pointerClick}>
              Don't have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="email"
            disabled={formDisabled}
            placeholder="Email"
            label={formFields.email.errorMessage || "Email"}
            error={!!formFields.email.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            margin="normal"
            name="email"
            required
            fullWidth
          />


          <TextField
            placeholder="Password"
            label={formFields.password.errorMessage || "Password"}
            error={!!formFields.password.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            fullWidth
            disabled={formDisabled}
            required
            name="password"
            autoComplete="current-password"
            type="password"
            margin="normal"
          />

          <Button type="submit" className={classes.button} disabled={submitDisabled} fullWidth onClick={submit}>
            {"Sign In"}
          </Button>
          <Typography variant="body2" align="center">
            <Link onClick={() => navigate("/sign-lost-password")} underline="always" className={classes.pointerClick}>
              lost password?
            </Link>
          </Typography>
        </form>
        <FormFeedback error={!!feedBackError} className={classes.feedback} success={!!feedBackSuccess}>
          {feedBackError || feedBackSuccess}
        </FormFeedback>
        <Typography variant="body2" align="center">
            *NB Email address is case sensitive. When logging onto a different device, please ensure you enter the exact same e-mail.
          </Typography>
      </AppForm>
    </React.Fragment>
  )
}
export default withStyles(styles)(Signin)
